import { Column } from '../interface/table';
import { DataTableCoulmnTypes } from '../enums/data-table-activity';

export const userListColumns: Column[] = [
  { key: 'fullName', name: 'Name' },
  { key: 'email', name: 'Email' },
  { key: 'phoneNumber', name: 'Contact No' },
  { key: 'lastActivityOn', name: 'Last Activity On', type: DataTableCoulmnTypes.DATE },
  { key: 'agent', name: 'Agent', type: DataTableCoulmnTypes.AGENT },
  { key: 'country', name: 'Country', type: DataTableCoulmnTypes.COUNTRY },
  { key: 'workingStatus', name: 'Working Status' },
];

export const adminUserListColumns: Column[] = [
  { key: 'fullName', name: 'Name' },
  { key: 'email', name: 'Email' },
  { key: 'state', name: 'State' },
  { key: 'country', name: 'Country', type: DataTableCoulmnTypes.COUNTRY },
  { key: 'createdOn', name: 'Joining Date', type: DataTableCoulmnTypes.DATE },
];

export const unpaidUserListColumns: Column[] = [
  { key: 'fullName', name: 'Name' },
  { key: 'email', name: 'Email' },
  { key: 'company', name: 'Company Name' },
  { key: 'unpaidAmount', name: 'Unpaid Amount' },
  { key: 'unpaidOrders', name: 'Unpaid Orders' },
];

export const inActiveUserListColumns: Column[] = [
  { key: 'fullName', name: 'Name' },
  { key: 'email', name: 'Email' },
  { key: 'lastOrderDate', name: 'Last Order Date' },
];
