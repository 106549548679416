import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

// Interfaces
import { Country } from 'src/app/shared/interface/country';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(private http: HttpClient) {}

  getCountriesList(): Promise<Country[]> {
    return this.http.get<Country[]>('country/getAll').toPromise();
  }
}
